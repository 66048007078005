<template>
    <div>
        <nav class="DocC">
            <div class="DocC-item">
                <div class="DocC-title" @click="toggleMenu('home')">
                    <span class="icon">
                        <i class="bi bi-image"></i>
                    </span>
                    <span class="recommend">图像生成</span>
                    <i class="caret" :class="{ 'bi-caret-left': !isHomeOpen, 'bi-caret-down': isHomeOpen }"></i>
                </div>
                <div class="offen_docs" v-if="isHomeOpen">
                    <DocComponent></DocComponent>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import DocComponent from './DocIcon.vue';

// Data
const isHomeOpen = ref(true);

// Methods
function toggleMenu() {
    isHomeOpen.value = !isHomeOpen.value;
}
</script>


<style scoped>
.DocC {
    display: flex;
    flex-direction: column;
    /* 使导航栏竖直排列 */
    background: rgba(240, 240, 240, 1);
    padding: 10px;
    width: 260px;
    margin-left: 20px;
    border-radius: 7px;
}

.DocC-item {
    display: flex;
    flex-wrap: wrap;
    /* 允许子元素换行 */
    color: black;
    padding: 10px;
    cursor: pointer;
}

.DocC-title {
    display: flex;
    width: 260px;
    align-items: center;
    /* 垂直居中 */
}

i {
    font-size: 24px;
}

.icon {
    font-size: 24px;
    width: 24px;
}

.recommend {
    margin-left: 20px;
    font-size: 18px;
}

.caret {
    margin-left: auto;
}

.DocC-title:hover {
    background-color: white;
}

.offen_docs {
    flex-basis: 100%;
}
</style>