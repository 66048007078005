<template>
  <div>
    <iframe :src="pdfSrc" class="DocPDF"></iframe>
  </div>
</template>
<script setup>
import { computed } from 'vue';

// 计算属性
const pdfSrc = computed(() => {
  return 'https://api.aizdc.com/static/document/Midjourney关键词合集.pdf';
});
</script>


<style scoped>
.DocDetails {
  margin-top: 45px;
  padding-bottom: 20px;
  font-size: 35px;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 7px;
  overflow: hidden;
  /* 避免溢出 */

}

.DocPDF {
  width: 1000px;
  height: 1000px;
  /* 你可以根据需要调整高度 */
  border: none;
  /* 去掉边框 */
}
</style>