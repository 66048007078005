<template>
    <div class="Doc" ref="Doc" @click="handleClick">
        <div class="Doc_icon">
            <img src="http://api.aizdc.com/static/document/MJlogo.png">
        </div>

        <div class='Doc_name'>
            MJ关键词
        </div>
    </div>
</template>

<script setup>
import emitter from '@/utils/EventBus';
function handleClick() {
    emitter.emit('sendguide', 'false');
}
</script>

<style scoped>
.Doc {
    margin-top: 10px;
    display: flex;
    background-color: aliceblue;
    width: 230px;
    height: 60px;
    cursor: pointer;
    border-radius: 10px;
}

.Doc:hover {
    background-color: white !important;
}


.Doc_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.Doc_icon img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.Doc_name {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    width: 150px;
    margin-top: 20px;
    margin-left: 10px;
}
</style>