<template>
    <div class="home">
        <div class="docList">
            <DocListComponent></DocListComponent>
        </div>

        <div class="guide" v-if="guide_vue">
            <DocGuideComponent></DocGuideComponent>
        </div>

        <div class="DocDetail" v-if="!guide_vue">
            <DocDetailsComponent></DocDetailsComponent>
        </div>

        <div class="announcementList">
            <AnnouncementListComponent></AnnouncementListComponent>
        </div>

    </div>
</template>

<script setup>

import DocListComponent from '@/views/Doc/Web_DocumentList.vue';
import DocGuideComponent from '@/views/Doc/Web_DocGuidance.vue';
import AnnouncementListComponent from '@/views/Web_AnnouncementList.vue';
import DocDetailsComponent from '@/views/Doc/Web_DocDetails.vue';
import emitter from '@/utils/EventBus';
import { ref, onMounted, onUnmounted } from 'vue';

const guide_vue = ref(true);

const handleguideMessage = (msg) => {
    console.log('Received message:', msg);
    guide_vue.value = msg === 'true';
};

onMounted(() => {
    emitter.on('sendguide', handleguideMessage);

});

onUnmounted(() => {
    emitter.off('sendguide', handleguideMessage);

});

</script>


<style scoped>
.home {
    display: flex;
    width: 100%;
    height: 100%;
}

.docList {
    width: 20%;
}

.guide {
    width: 1000px;
    height: 100%;
}

.announcementList {
    padding-top: 1%;
    width: 20%;
    margin-left: auto;
    margin-right: 2%;
}
</style>