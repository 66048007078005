<template>
    <div class="guide">
        <div class="guideText">
            <img class="logo" src="https://api.aizdc.com/static/document/jinmu.jpg">
            <p class="sayHi">你好,{{ username }}</p>
            <p class="help">点击左侧列表中的文档，进行查看吧！</p>
            <div class="left"> ← </div>
        </div>
    </div>

</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

// 获取 store 实例
const store = useStore();

// 计算属性
const username = computed(() => {
    const name = store.getters.username;
    console.log('用户名:', name); // 检查是否获取到用户名
    return name;
});
</script>

<style scoped>
.guide {
    margin-top: 45px;
    padding-bottom: 20px;
    font-size: 35px;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 7px;
}

.guide Text {
    height: 1000px;
}

.logo {
    margin-top: 20px;
    margin-left: 20px;
    width: 40px;
    border-radius: 50%;
}


p {
    margin-left: 50px;
}

.sayHi {
    margin-top: 0%;
    margin-bottom: 10px;
}

.help {
    margin-top: 0px;
    font-size: 22px;
}

.left {
    margin-top: 0px;
    margin-left: 50px;
    font-size: 100px;
}
</style>