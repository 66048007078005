<template>
    <div class="AmberBOT" ref="AmberBOT" @click="handleClick">
        <div class="bot_icon">
            <img :src="imageUrl" alt="Description of the image">
        </div>

        <div class='bot_name'>
            {{ title }}
        </div>
    </div>
</template>

<script>
import emitter from '@/utils/EventBus';

export default {
    name: 'LucyComponent',
    data() {
        return {
            title: '配音助理-Amber',
            id: '7427174942183800884',
            imageUrl: 'https://s2.loli.net/2024/10/19/6bSNsxnCKAFMDqL.jpg',
        };
    },
    mounted() {
        this.initializeChat(); // 初始化聊天
    },

    methods: {
        handleClick() {
            this.initialize1(); // 每次点击时调用 initialize()
            emitter.emit('sendId', this.id);
            emitter.emit('sendImageUrl', this.imageUrl);
            emitter.emit('sendTitle', this.title);
            console.log('bot已被点击,数据发送');

        },

        initialize1() {
            // 使用 MutationObserver 监听聊天窗口的出现,点击关闭
            const observer = new MutationObserver((mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const semiButtonContent = document.querySelector('.semi-button-content');
                        if (semiButtonContent) {
                            // 一旦找到 .semi-button-content 元素，绑定点击事件监听器
                            semiButtonContent.addEventListener('click', () => {
                                emitter.emit('sendHello', 'true');

                                console.log('.semi-button-content 被点击');
                            });

                            // 找到 .semi-button-content 元素后可以停止观察
                            observer.disconnect();
                            console.log('已找到 .semi-button-content，并绑定了点击事件');
                        }
                    }
                }
            });

            // 监听整个文档的子树变化
            observer.observe(document.body, {
                childList: true, // 监听直接子节点的变化
                subtree: true,   // 监听整个子树中的变化
            });
        },

        initializeChat() {
            this.$nextTick(() => {
                const AmberBOTElement = this.$refs.AmberBOT;

                if (AmberBOTElement) {
                    AmberBOTElement.addEventListener('click', () => {
                        const element = document.querySelector('.fda3723591e0b38e7e52 img');

                        if (element) {
                            element.click();
                            console.log('.fda3723591e0b38e7e52 img 已被点击');
                        } else {
                            console.error('.fda3723591e0b38e7e52 img 未找到');
                        }

                        emitter.emit('sendHello', 'false');
                        emitter.emit('MiaPrompt', 'false');
                        setTimeout(() => {
                            const chatWindow = document.querySelector('.fe3173ed6eda3342aedf');
                            if (chatWindow) {
                                chatWindow.style.width = '54%';
                                chatWindow.style.height = '90%';
                                chatWindow.style.position = 'fixed';
                                chatWindow.style.zIndex = '10000';
                                chatWindow.style.top = '56%';
                                chatWindow.style.left = '48%';
                                chatWindow.style.transform = 'translate(-50%, -50%)';

                            } else {
                                console.error('未找到聊天窗口元素');
                            }
                        }, 100);
                    });
                } else {
                    console.error('无法找到 AmberBOT 元素');
                }
            });
        }
    }
};
</script>

<style scoped>
.AmberBOT {
    margin-top: 10px;
    display: flex;
    background-color: aliceblue;
    width: 230px;
    height: 60px;
    cursor: pointer;
}

.AmberBOT:hover {
    background-color: white !important;
}

.bot_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.bot_icon img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.bot_name {
    display: flex;
    flex-direction: row;
    width: auto;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 18px;
}
</style>